<template>
  <div>
    <v-dialog v-model="newSheetDialogShow" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" large color="primary">
          <v-icon small left>fas fa-plus</v-icon>Thêm bảng tính lương
        </v-btn>
      </template>
      <QuickNewSheet></QuickNewSheet>
    </v-dialog>

    <v-data-table
      style="margin-top:20px"
      :loading="isLoading"
      :headers="headers"
      :items="tableData"
      class="elevation-1"
    >
      <template v-slot:item.sth_from="{ item }">{{ item.sth_from|toDMY }}</template>
      <template v-slot:item.sth_to="{ item }">{{item.sth_to|toDMY}}</template>
      <template v-slot:item.sth_status="{ item }">
        <v-chip
          label
          :color="item.sth_status|salarySheetStatusColor"
          outlined
        >{{ item.sth_status|salarySheetStatusText }}</v-chip>
      </template>
      <template v-slot:item.w_links="{ item }">
        <v-btn-toggle dense color="#033">
          <router-link :to="'/salary/salary-sheets/'+item.id">
            <v-btn depressed small color="blue darken-1" text-color="white">
              <i class="fas fa-ellipsis-h" style="color:white"></i>
            </v-btn>
          </router-link>
        </v-btn-toggle>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { getSalarySheetHeaders } from "@/api/salary";
import QuickNewSheet from "./components/QuickNewSheet";
export default {
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Lương", route: "/salary/salary-sheets" },
      { title: "Bảng tính lương", route: "/salary/salary-sheets" }
    ]);
    this.fetchData();
  },
  components: {
    QuickNewSheet
  },
  data() {
    return {
      newSheetDialogShow: false,
      headers: [
        { text: "Tên", value: "sth_name" },
        { text: "Từ", value: "sth_from" },
        { text: "Đến", value: "sth_to" },
        { text: "Trạng thái", value: "sth_status",align: "center"  },
        { text: "Links", value: "w_links", align: "center" }
      ],
      tableData: [],
      isLoading: false
    };
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      getSalarySheetHeaders().then(res => {
        this.isLoading = false;
        this.tableData = res.data;
      });
    }
  }
};
</script>