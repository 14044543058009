var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-plus")]),_vm._v("Thêm bảng tính lương ")],1)]}}]),model:{value:(_vm.newSheetDialogShow),callback:function ($$v) {_vm.newSheetDialogShow=$$v},expression:"newSheetDialogShow"}},[_c('QuickNewSheet')],1),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"margin-top":"20px"},attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.tableData},scopedSlots:_vm._u([{key:"item.sth_from",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("toDMY")(item.sth_from)))]}},{key:"item.sth_to",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("toDMY")(item.sth_to)))]}},{key:"item.sth_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":_vm._f("salarySheetStatusColor")(item.sth_status),"outlined":""}},[_vm._v(_vm._s(_vm._f("salarySheetStatusText")(item.sth_status)))])]}},{key:"item.w_links",fn:function(ref){
var item = ref.item;
return [_c('v-btn-toggle',{attrs:{"dense":"","color":"#033"}},[_c('router-link',{attrs:{"to":'/salary/salary-sheets/'+item.id}},[_c('v-btn',{attrs:{"depressed":"","small":"","color":"blue darken-1","text-color":"white"}},[_c('i',{staticClass:"fas fa-ellipsis-h",staticStyle:{"color":"white"}})])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }