<template>
  <v-card>
    <v-card-title class="headline grey lighten-2" primary-title>Thêm tài khoản mới</v-card-title>

    <v-card-text style="margin-top:20px">
      <v-text-field v-model="name" dense label="Tên bảng tính" outlined></v-text-field>
      <v-text-field v-model="from" type="date" dense label="Từ" outlined></v-text-field>
      <v-text-field v-model="to"  type="date" dense label="Đến" outlined></v-text-field>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" :loading="isLoading" @click="creatNewUserClick()">Tạo bảng tính mới</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import {createNewSalarySheet} from '@/api/salary'
import {showToast} from '@/utils/index.js'
export default {
  computed: {


  },
  created(){
      let firstday = Date.today().set({day:1});
      this.from = firstday.toString('yyyy-MM-dd');
      this.to = Date.today().addDays(-1).toString('yyyy-MM-dd');
      this.name = "Lương tháng "+(firstday.getMonth()+1);
  },
  methods:{
      creatNewUserClick(){
          if(!this.name||!this.from||!this.to){
              showToast(this,'error','Nhập đủ dữ liệu');
              return;
          }
          this.isLoading = true
          createNewSalarySheet({
              name:this.name,
              from:this.from,
              to:this.to,
          }).then(res=>{
            this.$router.push('/salary/salary-sheets/'+res.data.id);
              this.isLoading = false;


          }).catch(()=>{
              this.isLoading = false;
              // showToast(this,'error','Không thành công, hãy thử tên khác');
          })
      }
  },
  data() {
      return {
        name: "",
        from: "",
        to: "",
        isLoading: false
      };
    }
};
</script>